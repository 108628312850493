<template>
  <div>
    <Nav />
    <div class="center" style="background: #fff;padding: 30px;">
        <div class="top-info">
             <div class="flex2">
                <div style="width:350px;">
                    <h3 class="mb10" style="margin-bottom:10px">XXX商业用地</h3>
                    <img src="~@/assets/img/supply/1.webp" alt="" style="width:350px;height:250px">
                    <div class="mt30">
                        <img src="~@/assets/img/supply/1.webp" alt="" style="width:100px;height:65px;border:1px solid red;">
                        <div class="mt10">
                            <span><i class="el-icon-shopping-cart-2"></i></span>
                            <span class="mr15" style="color:red;font-size:14px;">加入关注</span>
                            <span><i class="el-icon-search"></i></span>
                            <span style="color:#ccc;font-size:14px;">116次围观</span>
                        </div>
                    </div>
                </div>
                <div class="ml15" style="flex:1">
                    <div>
                        倒计时：
                        <span class="time mr10">60</span>
                        <span class="mr10">天</span>
                        <span class="time mr10">12</span>
                        <span class="mr10">时</span>
                        <span class="time mr10">12</span>
                        <span class="mr10">分</span>
                        <span class="time mr10">32</span>
                        <span class="mr10">秒</span>
                    </div>
                    <div class="mt15">
                        当前竞拍价：
                        <span class="pice">4330</span>
                        万元
                    </div>
                    <div class="mt30" style="text-align: center;">
                        <el-button type="danger" style="background:red;width: 150px;">报名</el-button>
                    </div>
                    <div style="margin-top:60px;">
                        <div class="alert-title">
                             <span>成交后，需向中拍平台另付0.15%的软件使用费</span>
                             <div style="cursor:pointer">查看详情>></div>
                        </div>
                        <div class="alert-title mt15">
                             <span>成交后，需向拍卖企业另付1%的拍卖佣金</span>
                        </div>
                    </div>
                    <div class="mt30" style="padding-top: 15px;border-top: 1px solid #d0cdcd;">
                        <el-row :gutter="20">
                            <el-col :span="8">
                              <span style="color:#ccc">起拍价：</span>
                              <span>42705元</span>
                            </el-col>
                            <el-col :span="8">
                                <span style="color:#ccc">保证金：</span>
                                <span>42705元</span>
                            </el-col>
                            <el-col :span="8">
                                <span style="color:#ccc">加价幅度：</span>
                                <span style="color:red">42705元</span>
                            </el-col>
                            <el-col :span="8" class="mt10">
                                <span style="color:#ccc">评估价：</span>
                                <span>42705元</span>
                            </el-col>
                            <el-col :span="8" class="mt10">
                                <span style="color:#ccc">保留价：</span>
                                <span>无</span>
                            </el-col>
                            <el-col :span="8" class="mt10">
                                <span style="color:#ccc">优先购买权人：</span>
                                <span>无</span>
                            </el-col>
                            <el-col :span="12" class="mt10">
                                <span style="color:#ccc">自由竞价时间：</span>
                                <span>5分0秒</span>
                            </el-col>
                            <el-col :span="12" class="mt10">
                                <span style="color:#ccc">限时竞价时间：</span>
                                <span>5分0秒</span>
                            </el-col>
                            <el-col :span="24" class="mt10">
                                <span style="color:#ccc">报名限制：</span>
                                <span>5分0秒</span>
                            </el-col>
                            <el-col :span="24" class="mt10">
                                <span style="color:#ccc">所在地：</span>
                                <span>四川省德阳市</span>
                            </el-col>
                        </el-row>
                    </div>
                </div>
             </div>
             <div style="width:20px"></div>
             <div class="flex1">
                <div class="head">
                    <div class="active">拍卖师发言</div>
                    <div>拍卖师信息</div>
                </div>
                <div class="des ml15 mt10">拍卖成交后不退不换</div>
                <div class="mt10">
                    <div class="ml15">
                        <span style="color:#e20de7;">拍卖会</span>
                        <span style="margin:0 5px">|</span>
                        <span style="font-size:12px">2023年2月15日 13:00:47</span>
                    </div>
                    <div class="ml15" style="background:#ccc;padding:5px 15px;margin-top:5px;">
                            如遇任何问题均可随时联系我公司：131313131（微信号同步）
                    </div>
                    <div class="ml15" style="background:#ccc;margin-top: 5px;padding:5px 15px;">
                        拍卖成交后不退不换
                    </div>
                </div>
                <div class="mt10">
                    <div class="ml15">
                        <span style="color:#e20de7;">拍卖会</span>
                        <span style="margin:0 5px">|</span>
                        <span style="font-size:12px">2023年2月15日 13:00:47</span>
                    </div>
                    <div class="ml15" style="background:#ccc;padding:5px 15px;margin-top:5px;">
                            如遇任何问题均可随时联系我公司：131313131（微信号同步）
                    </div>
                    <div class="ml15" style="background:#ccc;margin-top: 5px;padding:5px 15px;">
                        拍卖成交后不退不换
                    </div>
                </div>
                <div class="mt10">
                    <div class="ml15">
                        <span style="color:#e20de7;">拍卖会</span>
                        <span style="margin:0 5px">|</span>
                        <span style="font-size:12px">2023年2月15日 13:00:47</span>
                    </div>
                    <div class="ml15" style="background:#ccc;padding:5px 15px;margin-top:5px;">
                            最后祝各位竞拍人竞拍顺利
                    </div>
                </div>
             </div>
        </div>
        <div style="margin-top:60px">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="重要提示" name="first">
                    一、竞买人须是具有依法设立并有效存续的企业法人或具有完全民事行为能力的自然人。其中;
                    1.法人竞买人须提交有效企业法人营业执熙等证照原件或复印件（均需加盖公章)，法人授权委托书、法定代表人身份证明)款项到账时间以银联及发卡行人)的身份证原件及复印件等资料;
                    2.自然人竞买人须提交本人有效身份证原件，个体工商户营业执照（或同类市场经营合同复印件)。
                    二、本次拍卖采取分场次单体拍卖。竞买人须自行在中拍平台报名并申请参拍，并按照拍卖公司要求交纳竞买保证金1万元/个(户名:辽台使用费(如买受司，账号:152624000000000045，开户行:沈阳农村商业银行股份有限公司陵东支行，行号31422100920)，逾期无法取得参拍资格。
                    2拍卖会未成交的竞买人交付的保证金须待拍卖会结束后的3个工作日内原路径返还（不计利息)，拍卖会中途不返还保证金
                    款项到账时间以银联及发卡行款项到账时间以银联及发卡行
                </el-tab-pane>
                <el-tab-pane label="拍卖公告" name="second">拍卖公告</el-tab-pane>
                <el-tab-pane label="竞卖须知" name="third">竞卖须知</el-tab-pane>
                <el-tab-pane label="标的介绍" name="fourth">标的介绍</el-tab-pane>
                <el-tab-pane label="竞价记录" name="five">竞价记录</el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <footer2 />
  </div>
</template>

<script>
import Nav from '@/components/nav'
import footer2 from "components/footer.vue";
export default {
    name:'auction',
    components:{
        Nav,
        footer2
    },
    data(){
        return{
            activeName:'first'
        }
    }
}
</script>

<style lang="scss" scoped>
.active{
    color: red;
    border-right: 1px solid red;
}
.top-info{
    display: flex;
    font-size: 14px;
    .flex1{
        width: 320px;
        .head{
            display: flex;
            div{
                flex: 1;
                text-align: center;
            }
        }
        .des{
            padding: 5px 10px 5px 15px;
            background: #ccc;
        }
       
    }
    .flex2{
        flex: 1;
        display: flex;
    }
    .time{
        background: #409EFF;
        padding: 5px 5px;
        border-radius: 5px;
        color: #fff;
        font-size: 18px;
        // margin-right: 10px;
    }
    .no{
        font-size: 18px;
        color: red;
    }
    .pice{
        font-size: 22px;
        color: red;
    }
    .alert-title{
        display: flex;
        justify-content: space-between;
        background: #F56C6C;
        padding: 8px 16px;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
    }

}
</style>